import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import ThankYouWrapper from "../components/ThankYouWrapper";
import CardBlog from "../components/CardBlog";
import NewsletterForm from "../components/NewsletterForm";
import IconFacebook from "../images/icons/social/facebook-white.svg";
import IconTwitter from "../images/icons/social/twitter-white.svg";
import IconLinkedIn from "../images/icons/social/linkedin-white.svg";

const ChatgptuxresearchPage = ({ data, location: { pathname } }) => {
  const latestArticle = data.recentArticles?.edges
    ? data.recentArticles?.edges[0]?.node
    : undefined;

  return (
    <Layout
      /* hideFooter */
      footerBgClass="bg-white"
      meta={{
        seo: {
          title: "138 ChatGPT Prompts: UX Research",
        },
        path: pathname,
      }}
    >
      <div className="thank-you-page">
        <ThankYouWrapper
          title="ChatGPT Prompts for UX Research"
          /* description=" Your download is on its way to your mailbox! Please double check your spam folder." */
          middleBlock={
            <div className="pt-2 middle-block">
              <Link
                to="https://www.notion.so/adamfard/138-ChatGPT-Prompts-UX-Research-a942587dc94f4f979b416bead3412f8c"
                id="freeconsultation"
                target="_blank"
                className="btn btn-lg btn-primary"
              >
                Get the 138 ChatGPT Prompts Now
              </Link>
              {/* <p className="lead mt-3 middle-description">
                Access the 138 ChatGPT Prompts here
              </p> */}
            </div>
          }
          socialLinksBlock={
            <div className="social-buttons-container">
              <div className="lead mt-3">
                <span className="badge badge-primary-2 mb-2 mt-4 lead">
                  Live workshop:
                </span>
                <p>
                  Interested in applying actionable research methods to your startup?
                </p>
                <a href="https://adamfard.com/live-ux-course-workshop" target="_blank" className="lead mt-2 hover-arrow">Reserve the last seats in our workshop</a>
              </div>

              {/* <a
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fadamfard.com%2Fdownload-ux-resources"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 text-white align-items-center social-button facebook"
              >
                <IconFacebook className="mr-2" />
                <span>Facebook</span>
              </a>
              <a
                href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fadamfard.com%2Fdownload-ux-resources&text=Check%20these%20Free%20UX%20Design%20Resources%3A"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 text-white align-items-center social-button twitter"
              >
                <IconTwitter className="mr-2" />
                <span>Twitter</span>
              </a>
              <a
                href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fadamfard.com%2Fdownload-ux-resources&title=Check%20these%20Free%20UX%20Design%20Resources%3A"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 text-white align-items-center social-button linkedin"
              >
                <IconLinkedIn className="mr-2" />
                <span>LinkedIn</span>
              </a> */}

            </div>
          }
          article={<CardBlog key={latestArticle.id} cardData={latestArticle} />}
        />
      </div>
    </Layout>
  );
};

export default ChatgptuxresearchPage;

export const query = graphql`
  query ChatgptuxresearchQuery {
    recentArticles: allDatoCmsArticle(
      limit: 1
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(width: 1024, layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
